import React from 'react';
import {FormHelperText, Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom} from "jotai";
import {pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";
import 'dayjs/locale/es';
import DivContainer1 from '../common/DivContainer1';

function HorasMaquinaria() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }



    return (
        <QuestionsContainer title={'Horas maquinaria fuera de servicio'}>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="horas_zaranda_fuera_servicio">Horas Zaranda fuera de servicio</InputLabel>
                    <Input
                        id="horas_zaranda_fuera_servicio"
                        aria-describedby="horas_zaranda_fuera_servicio"
                        value={newPozo.horas_zaranda_fueraservicio}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'horas_zaranda_fueraservicio')}
                    />
                    <FormHelperText >Horas que no se pudieron utilizar mientras eran necesarias</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="horas_centrifuga_fueraservicio">Horas Centrifuga fuera de servicio</InputLabel>
                    <Input
                        id="horas_centrifuga_fueraservicio"
                        aria-describedby="horas_centrifuga_fueraservicio"
                        value={newPozo.horas_centrifuga_fueraservicio}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'horas_centrifuga_fueraservicio')}
                    />
                    <FormHelperText >Horas que no se pudieron utilizar mientras eran necesarias</FormHelperText>
                </StyledFormControl>
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default HorasMaquinaria;
