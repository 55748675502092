import React from 'react';
import ExportForm from '../components/exportar/ExportForm';

function ExportarDataPozosPage() {
    document.title = 'Exportar pozo';
    return (
        <ExportForm />
    );
}

export default ExportarDataPozosPage;