import {LinearProgress} from "@mui/material";
import React from "react";

export const Loading = () => {
    return (
        <div>
            <h1>Cargando...</h1>
            <LinearProgress />
        </div>
    )
};