import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React, { useState } from 'react';
import {useAtomValue, useSetAtom} from "jotai";
import {equiposAtom2} from "../../../store/atoms/pozos/pozos.atom";
import {loadPozosAtom} from "../../../store/atoms/pozos/pozos.actions";

const TeamSelector = ({handleDataChange}: {handleDataChange: (data: string[]) => void}) => {
    const loadPozos = useSetAtom(loadPozosAtom);
    const teams = useAtomValue(equiposAtom2);

    // Initialize state with filtered teams
    const [selectedTeams, setSelectedTeams] = useState<string[]>(teams);

    // Load Pozos on component mount
    React.useEffect(() => {
        loadPozos();
    }, [loadPozos]);

    React.useEffect(() => {
        handleDataChange(teams);
        setSelectedTeams(teams)
    }, [handleDataChange, teams]);

    // Improved type safety and handling for the change event
    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const newSelectedTeams = event.target.value as string[];
        setSelectedTeams(newSelectedTeams);
        handleDataChange(newSelectedTeams);
    };


    return (
        <FormControl fullWidth >
            <InputLabel id="team-select-label">Equipos</InputLabel>
            <Select variant={'outlined'}
                labelId="team-select-label"
                id="team-select"
                multiple
                value={selectedTeams}
                onChange={handleChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        } }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 224,
                            width: 250,
                        },
                    },
                }}
            >
                {teams.map((team) => (
                    <MenuItem key={team} value={team}>
                        <Checkbox checked={selectedTeams.indexOf(team) > -1} />
                        <ListItemText primary={team} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TeamSelector;