import React from 'react';
import QuestionsContainer from "../formulario/QuestionsContainer";
import DetalleTrepanoSeccion from "./DetalleTrepanoSeccion";
import {useAtom, useAtomValue} from "jotai";
import {
    mbt_promedioAtom,
    pozoCreationAtom, pozoCreationModel,
    tdAtom,
    volumenTeoricoPozoAtom
} from "../../store/atoms/pozos/pozos.actions";
import DivContainer1 from '../common/DivContainer1';
import StyledFormControl from '../common/StyledFormControl';
import {Input, InputLabel} from "@mui/material";

function PromedioMBT(props: { promedioMBT: number }) {
    return <StyledFormControl>
        <InputLabel htmlFor="mbt_promedio">MBT Promedio</InputLabel>
        <Input
            id="mbt_promedio"
            aria-describedby="mbt_promedio"
            value={props.promedioMBT || 0}
        />
    </StyledFormControl>;
}

function TotalMallasPozo() {
    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }

    return <StyledFormControl>
                <InputLabel htmlFor="cantidad_mallas_utilizadas">Total de mallas utilizadas en el pozo</InputLabel>
                <Input
                    id="cantidad_mallas_utilizadas"
                    aria-describedby="cantidad_mallas_utilizadas"
                    value={newPozo.cantidad_mallas_utilizadas}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'cantidad_mallas_utilizadas')}
                />
            </StyledFormControl>;
}

function TDFinal() {
    const td = useAtomValue(tdAtom);
    const volumenTeorico = useAtomValue(volumenTeoricoPozoAtom);

    return (
        <div>
            <StyledFormControl>
                <InputLabel htmlFor="td_total">TD Total</InputLabel>
                <Input
                    id="td_total"
                    aria-describedby="td_total"
                    value={td || 0}
                />
            </StyledFormControl>

            <StyledFormControl>
                <InputLabel htmlFor="volumen_teorico">Volumen Teorico</InputLabel>
                <Input
                    id="volumen_teorico"
                    aria-describedby="volumen_teorico"
                    value={volumenTeorico || 0}
                />
            </StyledFormControl>
        </div>



    );
}

function DetallesTrepano() {
    const promedioMBT = useAtomValue(mbt_promedioAtom);
    return (
        <QuestionsContainer title={'Detalles trepano'}>
            <DivContainer1>
                <DetalleTrepanoSeccion seccion={0} name={`guia`}/>
                <DetalleTrepanoSeccion seccion={1} name={`sección #2`}/>
                <DetalleTrepanoSeccion seccion={2} name={`sección #3`}/>
                <DetalleTrepanoSeccion seccion={3} name={`sección #4`}/>
            </DivContainer1>
            <TotalMallasPozo />
            <TDFinal />

            <PromedioMBT promedioMBT={promedioMBT}/>


        </QuestionsContainer>
    );
}

export default DetallesTrepano;