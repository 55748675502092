import React from 'react';
import {Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtomValue} from "jotai";

import {
    costo_control_solidos_entre_m3,
    costo_control_solidos_entre_metro_perf,
    costo_loc_seca_entre_m3,
    costo_loc_seca_entre_metro_perf,
    costo_loc_seca_mas_control_solid,
    costo_loc_seca_mas_control_solid_entre_m3,
    costo_loc_seca_mas_control_solid_entre_metro_perf
} from "../../store/atoms/pozos/pozoCostos.atom";
import {replaceIfNaN} from "../../helpers/formatters";
import DivContainer1 from '../common/DivContainer1';

function CostoDetalles() {
    return (
        <QuestionsContainer title={'Detalles de costos'}>
            <StyledFormControl>
                <InputLabel htmlFor="costos_locacion_seca_mas_control_solidos">locación seca + control de solidos</InputLabel>
                <Input
                    id="costos_locacion_seca_mas_control_solidos"
                    aria-describedby="costos_locacion_seca_mas_control_solidos"
                    value={replaceIfNaN(useAtomValue(costo_loc_seca_mas_control_solid), 0)}
                    onChange={() => {}}
                />
            </StyledFormControl>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_loc_seca_entre_metro_perf">locación seca / metro perforado </InputLabel>
                    <Input
                        id="costo_loc_seca_entre_metro_perf"
                        aria-describedby="costo_loc_seca_entre_metro_perf"
                        value={replaceIfNaN(useAtomValue(costo_loc_seca_entre_metro_perf), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_control_solido_entre_metro_perf">Control de solidos / metro perforado</InputLabel>
                    <Input
                        id="costo_control_solido_entre_metro_perf"
                        aria-describedby="costo_control_solido_entre_metro_perf"
                        value={replaceIfNaN(useAtomValue(costo_control_solidos_entre_metro_perf), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_loc_seca_mas_control_solidos_entre_m_perf">(Locación seca + Control solidos) / metro perforado</InputLabel>
                    <Input
                        id="costo_loc_seca_mas_control_solidos_entre_m_perf"
                        aria-describedby="costo_loc_seca_mas_control_solidos_entre_m_perf"
                        value={replaceIfNaN(useAtomValue(costo_loc_seca_mas_control_solid_entre_metro_perf), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
            </DivContainer1>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_loc_seca_entre_m3">Locación seca / m³</InputLabel>
                    <Input
                        id="costo_loc_seca_entre_m3"
                        aria-describedby="costo_loc_seca_entre_m3"
                        value={replaceIfNaN(useAtomValue(costo_loc_seca_entre_m3), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_control_solidos_entre_m3">Control de solidos / m³</InputLabel>
                    <Input
                        id="costo_control_solidos"
                        aria-describedby="costo_control_solidos"
                        value={replaceIfNaN(useAtomValue(costo_control_solidos_entre_m3), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="costo_loc_seca_mas_control_solidos_entre_m3">(Locación seca + Control de solidos) / m³</InputLabel>
                    <Input
                        id="costo_loc_seca_mas_control_solidos_entre_m3"
                        aria-describedby="costo_loc_seca_mas_control_solidos_entre_m3"
                        value={replaceIfNaN(useAtomValue(costo_loc_seca_mas_control_solid_entre_m3), 0)}
                        onChange={() => {}}
                    />
                </StyledFormControl>
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default CostoDetalles;
