import React from 'react';

function DivContainer1({children}: {children: React.ReactNode}) {
    return (
        <div style={{display: 'flex', minWidth: '80ch'}}>
            {children}
        </div>
    );
}

export default DivContainer1;