import React from 'react';
import {Outlet} from "react-router-dom";

import './style.css'
import {MenuBar} from "../components/MenuBar";

export const Base = () => {
    return (
        <div>
            <div className="header">
                <MenuBar />
            </div>
            <div className="content">
                <Outlet/>
            </div>
        </div>
    );
};