import React from 'react';
import {FormHelperText, Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtomValue} from "jotai";
import {
    m3_evacuados_teoricosAtom,
    m3EvacuadosCuttingPerfAtom,
    m3EvacuadosEnviadosCanteraAtom, pozoCreationAtom, pozoCreationModel
} from "../../store/atoms/pozos/pozos.actions";
import DivContainer1 from "../common/DivContainer1";
import SuggestInputForm from "../formulario/SuggestInputForm";
import {destinosAtom} from "../../store/atoms/pozos/pozos.atom";
import {useAtom} from "jotai/index";

function DetallesM3Pozo() {
    const m3_evacuados_cut_perf = useAtomValue(m3EvacuadosCuttingPerfAtom);
    const m3_evacuados_env_cantera = useAtomValue(m3EvacuadosEnviadosCanteraAtom);
    const m3_evacuados_teoricos = useAtomValue(m3_evacuados_teoricosAtom);
    const destinos = useAtomValue(destinosAtom);
    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }

    return (
        <QuestionsContainer title={'Datos m3 evacuados'}>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="m3_cutting_dewatering">m³ de cutting por dewatering y floculación al sistema</InputLabel>
                    <Input
                        id="m3_cutting_dewatering"
                        aria-describedby="m3_cutting_dewatering"
                        value={newPozo.m3_cutting_dewatering}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'm3_cutting_dewatering')}

                    />
                    <FormHelperText >Ejemplo: 93</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="agua_dewatering">Agua de dewatering</InputLabel>
                    <Input
                        id="agua_dewatering"
                        aria-describedby="agua_dewatering"
                        value={newPozo.agua_dewatering}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'agua_dewatering')}
                    />
                    <FormHelperText >Ejemplo: 30</FormHelperText>
                </StyledFormControl>

                <StyledFormControl>
                    <SuggestInputForm
                        label={'Destino'}
                        suggestions={destinos}
                        forceOptions={false}
                        showValue={newPozo.destino}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'destino', newValue)}
                    />
                    <FormHelperText >Ejemplo: Recuperado</FormHelperText>
                </StyledFormControl>
                <StyledFormControl />
            </DivContainer1>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="m3_evacuados_cutting_perforacion">m³ evacuados cutting perforación</InputLabel>
                    <Input
                        id="m3_evacuados_cutting_perforacion"
                        aria-describedby="m3_evacuados_cutting_perforacion"
                        value={(isNaN(m3_evacuados_cut_perf) || m3_evacuados_cut_perf === 0) ? '' : m3_evacuados_cut_perf}
                        onChange={() => {}}
                    />
                </StyledFormControl>

                <StyledFormControl>
                    <InputLabel htmlFor="m3_evacuados_enviados_cantera">m³ evacuados enviados a cantera</InputLabel>
                    <Input
                        id="m3_evacuados_enviados_cantera"
                        aria-describedby="m3_evacuados_enviados_cantera"
                        value={(isNaN(m3_evacuados_env_cantera) || m3_evacuados_env_cantera === 0) ? '' : m3_evacuados_env_cantera}
                        onChange={() => {}}

                    />
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="m3_evacuado_teorico">m³ evacuado/teorico</InputLabel>
                    <Input
                        id="m3_evacuado_teorico"
                        aria-describedby="m3_evacuado_teorico"
                        value={m3_evacuados_teoricos || ''}
                        onChange={() => {}}
                    />
                </StyledFormControl>
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default DetallesM3Pozo;
