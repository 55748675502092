import {Outlet, Route, Routes} from "react-router-dom";
import {Base} from "../templates/Base";
import {HomePage} from "../pages/HomePage";
import {ImportExcelPage} from "../pages/ImportExcelPage";

import ExportarDataPozosPage from "../pages/ExportarDataPozosPage";
import DatosGeneralesPage from "../pages/nuevoPozo/DatosGeneralesPage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Base />}>
                    <Route path={'/'} element={<HomePage />} />
                    <Route path={'/importar-excel'} element={<ImportExcelPage />} />
                <Route path={'/insertar-pozo'} element={<Outlet />}>
                    <Route path={'datos-generales'} element={<DatosGeneralesPage />} />
                </Route>
                <Route path={'/pozo/exportar'} element={<ExportarDataPozosPage />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;