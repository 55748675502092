import React, {Suspense} from 'react';
import {ListadoPozos} from "../components/ListadoPozos/ListadoPozos";
import {Button, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {useAtom} from "jotai";
import {loadPozosAtom} from "../store/atoms/pozos/pozos.actions";
import {unloadPozosAtom} from "../store/atoms/pozos/pozos.atom";
import {Loading} from "../components/common/loading";

export const HomePage = () => {
    const [,loadPozo] = useAtom(loadPozosAtom);
    const [, unloadPozo] = useAtom(unloadPozosAtom);
    React.useEffect(() => {
        document.title = 'Inicio';
        loadPozo();
        return () => {
            document.title = 'Cargando...';
            unloadPozo();
        }
    }, [loadPozo, unloadPozo]);

    const RenderPage = () => {
        return (
            <div>
                <div style={{alignContent: 'right', padding: '0.5em'}}>
                    <Grid container spacing={1} justifyContent={"flex-end"}>
                        <Grid item>
                            <Link to={'/pozo/exportar'}><Button variant="contained" color="warning">Exportar</Button></Link>
                        </Grid>
                        <Grid item >
                            <Link to={'/insertar-pozo/datos-generales'}><Button variant="contained" color="success">Nuevo</Button></Link>
                        </Grid>
                        <Grid item>
                            <Link to={'/importar-excel'}><Button variant="contained" color="info">Importar</Button></Link>
                        </Grid>
                    </Grid>
                </div>

                <ListadoPozos />
            </div>
        );
    }

    return (
        <div className={'homePage'}>
            <Suspense fallback={<Loading />}>
                <RenderPage />
            </Suspense>
        </div>
    );
};