import React from 'react';
import {Checkbox, FormHelperText, Input, InputLabel} from "@mui/material";
import SuggestInputForm from "../formulario/SuggestInputForm";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom, useAtomValue} from "jotai";
import {pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";
import {areasAtom, equiposAtom, operadorasAtom, tiposPozoAtom} from "../../store/atoms/pozos/pozos.atom";
import FechaPicker from '../common/fecha/FechaPicker';
import DivContainer1 from '../common/DivContainer1';


function DatosGenerales() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);
    const operadoras = useAtomValue(operadorasAtom);
    const equipos = useAtomValue(equiposAtom);
    const tiposPozo = useAtomValue(tiposPozoAtom);
    const areas = useAtomValue(areasAtom);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | null, field: keyof pozoCreationModel, newValue: null | string | boolean | Date = null) => {
        if (event === null && newValue === null)
            return;
        if (event !== null && newValue !== null)
            setNewPozo({...newPozo, [field]: newValue || event.target.value});
        else if (event === null && newValue !== null)
            setNewPozo({...newPozo, [field]: newValue});
        else if (event !== null && newValue === null)
            setNewPozo({...newPozo, [field]: event.target.value});
    }

    return (
        <QuestionsContainer title={'Datos generales'}>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="nombre-pozo">Nombre del pozo</InputLabel>
                    <Input
                        id="nombre-pozo"
                        aria-describedby="nombre-pozo"
                        value={newPozo.pozo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'pozo')}
                    />
                    <FormHelperText>Ejemplo: PDV-001</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Operadora'}
                        suggestions={operadoras}
                        forceOptions={false}
                        showValue={newPozo.operadora}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'operadora', newValue)}
                    />
                    <FormHelperText>Selecciona o escribe una nueva operadora</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Equipo'}
                        suggestions={equipos}
                        forceOptions={false}
                        showValue={newPozo.equipo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'equipo', newValue)}
                    />
                    <FormHelperText>Selecciona o escribe un nuevo equipo</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Area'}
                        suggestions={areas}
                        forceOptions={false}
                        showValue={newPozo.area}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'area', newValue)}
                    />
                    <FormHelperText>Selecciona o escribe una nueva area</FormHelperText>
                </StyledFormControl>
            </DivContainer1>
            <DivContainer1>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Tipo de pozo'}
                        suggestions={tiposPozo}
                        forceOptions={false}
                        showValue={newPozo.tipo_pozo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'tipo_pozo', newValue)}
                    />
                    <FormHelperText>Selecciona o escribe el tipo de pozo</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Fase del pozo'}
                        suggestions={['Exploracion', 'Desarrollo']}
                        forceOptions={true}
                        showValue={newPozo.exploracion_desarrollo}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'exploracion_desarrollo', newValue)}
                    />
                </StyledFormControl>
                <StyledFormControl>

                    <FechaPicker dateValue={newPozo.fecha_ultimo_reporte}
                                 label={'Fecha del ultimo reporte'}
                                 format={'DD-MM-YYYY'}
                                 onChange={(val: Date | null) => handleChange(null, 'fecha_ultimo_reporte', val)}/>

                    <FormHelperText>Ejemplo: 25-05-2023</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="monobore">Monobore</InputLabel>
                    <Checkbox value={newPozo.monobore} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'monobore', event.target.checked)} />
                    <FormHelperText >{newPozo.monobore === true ?'Si':'No'}</FormHelperText>
                </StyledFormControl>
            </DivContainer1>

            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="latitud">Latitud</InputLabel>
                    <Input
                        id="latitud"
                        aria-describedby="latitud"
                        value={newPozo.coordenada_lat}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'coordenada_lat')}
                    />
                    <FormHelperText>Ejemplo: 10.123456</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="longitud">Longitud</InputLabel>
                    <Input
                        id="longitud"
                        aria-describedby="longitud"
                        value={newPozo.coordenada_long}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'coordenada_long')}
                    />
                    <FormHelperText>Ejemplo: -70.123456</FormHelperText>
                </StyledFormControl>

                <StyledFormControl />
                <StyledFormControl />
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default DatosGenerales;