import React from 'react';
import {FormHelperText, Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom, useAtomValue} from "jotai";
import {kg_m3_enviado_canteraAtom, pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";
import 'dayjs/locale/es';
import {canterasAtom} from "../../store/atoms/pozos/pozos.atom";
import SuggestInputForm from "../formulario/SuggestInputForm";
import { replaceIfNaN } from '../../helpers/formatters';
import DivContainer1 from '../common/DivContainer1';

function ProcesadoDewatering() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);
    const canteras = useAtomValue(canterasAtom);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }



    return (
        <QuestionsContainer title={'Procesado por Dewatering'}>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="m3_proceado_dewatering">m³ procesado por dewatering</InputLabel>
                    <Input
                        id="m3_proceado_dewatering"
                        aria-describedby="m3_proceado_dewatering"
                        value={newPozo.m3_procesado_dewatering}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'm3_procesado_dewatering')}
                    />
                    <FormHelperText >Ejemplo: 102</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="kg_polimero_consumido">KG polimero consumido</InputLabel>
                    <Input
                        id="kg_polimero_consumido"
                        aria-describedby="kg_polimero_consumido"
                        value={newPozo.kg_polimero_consumido}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'kg_polimero_consumido')}
                    />
                    <FormHelperText >Ejemplo: 200</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Cantera de destino'}
                        suggestions={canteras}
                        forceOptions={false}
                        showValue={newPozo.cantera_destino}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => handleChange(event, 'cantera_destino', newValue)}
                    />
                    <FormHelperText >Ejemplo: Agua Fresca</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="kg_enviado_cantera">Kg/m3 enviado a cantera</InputLabel>
                    <Input
                        id="kg_enviado_cantera"
                        aria-describedby="kg_enviado_cantera"
                        value={replaceIfNaN(useAtomValue(kg_m3_enviado_canteraAtom), '')}
                        onChange={() => {}}
                    />
                </StyledFormControl>
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default ProcesadoDewatering;
