import React from 'react';
import {styled} from "@mui/material";

const StyledDiv = styled('div')({
    margin: '1rem',
    padding: '1rem',
    width: '95%',
    alignItems: 'center',
});

function QuestionsContainer({title, children}: {title: string, children: React.ReactNode}) {
    return (
        <StyledDiv>
            <h3>{title}</h3>
            <hr/>
            {children}
        </StyledDiv>
    );
}

export default QuestionsContainer;