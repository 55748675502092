import React from "react";
import {LoginPanel} from "../components/login/LoginPanel";

export const LoginPage = () => {

    React.useEffect(() => {
        document.title = 'Reporteria - Iniciar sesión'
        document.body.style.background = 'linear-gradient(90deg, #e50103, #8c8ea6)';

        return () => {
            document.body.style.background = 'white';
        }
    }, [])

    return (
        <div className='login'>
            <LoginPanel/>
        </div>
    );
};