import {atom} from "jotai";
import {atomWithStorage, createJSONStorage} from "jotai/utils";
import axios from "axios";
import {errorsAtom} from "../system/errors.atom";

type usuario = {
    token: string | null;
    usuario: string;
    email: string;
    idTipo: number;
}

const defaultModel: usuario = {
        usuario: '',
        email: '',
        idTipo: 0,
        token: null,
}


export const usuarioLocalAtom = atomWithStorage<usuario>('usuario', defaultModel, createJSONStorage(() => localStorage));

export const isLoggedAtom = atom<Promise<boolean>>(async (get) => !!get(usuarioLocalAtom).token);

export const isAdminAtom = atom<boolean>((get) => get(usuarioLocalAtom).idTipo === 1);

export const isUserAtom = atom<boolean>((get) => get(usuarioLocalAtom).idTipo === 2);

export const isLogginAtom = atom<boolean>(false);

export const checkLoginAtom = atom(null, async (get, set, payload) => {
    set(isLogginAtom, true);
    axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, payload)
        .then((response) => {
            const {token, tipoUsuario, email} = response.data;
            set(usuarioLocalAtom, {...get(usuarioLocalAtom), token, idTipo: tipoUsuario, email});
            set(isLogginAtom, false);
        })
        .catch((error) => {
            console.log(error);
            set(isLogginAtom, false);
            set(errorsAtom, [...get(errorsAtom), 'Error al iniciar sesión']);
        });
});

export const getTokenAtom = atom((get) => get(usuarioLocalAtom).token);

export const logoutAtom = atom(null, (get, set) => {
    set(usuarioLocalAtom, defaultModel);
});