import React from 'react';
import {FormHelperText, Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom, useAtomValue} from "jotai";
import {m3_evacuado_por_viajeAtom, pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";
import {replaceIfNaN} from "../../helpers/formatters";
import DivContainer1 from '../common/DivContainer1';

function ViajesCantera() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }



    return (
        <QuestionsContainer title={'Detalles viaje a cantera'}>
            <DivContainer1>
                <StyledFormControl>
                    <InputLabel htmlFor="distancia_cantera">Distancia a cantera en KM</InputLabel>
                    <Input
                        id="distancia_cantera"
                        aria-describedby="distancia_cantera"
                        value={newPozo.distancia_cantera}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'distancia_cantera')}
                    />
                    <FormHelperText >ejemplo: 47</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="viajes_a_cantera">Viajes totales a cantera</InputLabel>
                    <Input
                        id="viajes_a_cantera"
                        aria-describedby="viajes_a_cantera"
                        value={newPozo.viajes_a_cantera}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'viajes_a_cantera')}
                    />
                    <FormHelperText >Veces que se viajaron a cantera</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="cantidad_batea_utilizadoxdia">Cantidad de batea utilizados por día</InputLabel>
                    <Input
                        id="cantidad_batea_utilizadoxdia"
                        aria-describedby="cantidad_batea_utilizadoxdia"
                        value={newPozo.cantidad_batea_utilizadoxdia}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'cantidad_batea_utilizadoxdia')}
                    />
                    <FormHelperText >Total disponible/dias operativos</FormHelperText>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel htmlFor="m3_evacuados_viaje">m3 evacuados por viaje</InputLabel>
                    <Input
                        id="m3_evacuados_viaje"
                        aria-describedby="m3_evacuados_viaje"
                        value={replaceIfNaN(useAtomValue(m3_evacuado_por_viajeAtom), 0)}
                        onChange={() => {}}
                    />
                    <FormHelperText >m3 evacuados enviados a cantera/Viajes totales a cantera</FormHelperText>
                </StyledFormControl>
            </DivContainer1>
        </QuestionsContainer>
    );
}

export default ViajesCantera;
