import React from 'react';
import {Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom} from "jotai";
import {pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";

function Observaciones() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }



    return (
        <QuestionsContainer title={'Observaciones'}>
            <StyledFormControl>
                <InputLabel htmlFor="observaciones">Observaciones del pozo</InputLabel>
                <Input
                    id="observaciones"
                    aria-describedby="observaciones"
                    multiline={true}
                    minRows={1}
                    maxRows={6}
                    value={newPozo.observaciones}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'observaciones')}
                />
            </StyledFormControl>
        </QuestionsContainer>
    );
}

export default Observaciones;
