import React, {useCallback} from 'react';
import {cleanPozoCreationAtom, loadPozosAtom} from "../../store/atoms/pozos/pozos.actions";
import {useSetAtom} from "jotai";
import DatosGenerales from "../../components/InsertarPozo/DatosGenerales";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import DetallesTrepano from "../../components/InsertarPozo/DetallesTrepano";
import DetallesM3Pozo from "../../components/InsertarPozo/DetallesM3Pozo";
import HorasMaquinaria from "../../components/InsertarPozo/HorasMaquinaria";
import ViajesCantera from "../../components/InsertarPozo/ViajesCantera";
import ProcesadoDewatering from "../../components/InsertarPozo/ProcesadoDewatering";
import CostosLocacion from "../../components/InsertarPozo/CostosLocacion";
import CostoDetalles from "../../components/InsertarPozo/CostoDetalles";
import Observaciones from "../../components/InsertarPozo/Observaciones";
import BotonEnviarPozo from "../../components/InsertarPozo/BotonEnviarPozo";

function DatosGeneralesPage() {
    const loadPozos = useSetAtom(loadPozosAtom);
    const cleanPozos = useSetAtom(cleanPozoCreationAtom);
    const navigate = useNavigate();

    const cleanPozo = useCallback(() => {
        cleanPozos();
    }, [cleanPozos]);

    React.useEffect(() => {
        document.title = 'Nuevo - Datos Generales';

        loadPozos();
        return () => {
            document.title = 'Cargando...';
            cleanPozo();
        }
    }, [loadPozos, cleanPozo]);

    return (
        <div>
            <div >
                <DatosGenerales/>
                <DetallesTrepano/>

                <DetallesM3Pozo />
                <div style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}>
                    <CostosLocacion />
                    <CostoDetalles />
                </div>
                <ProcesadoDewatering />
                <HorasMaquinaria />
                <ViajesCantera />
                <Observaciones />

            </div>
            <div style={{display: 'flex', justifyContent: 'center', gap: '1em'}}>
                <Button variant={'contained'} color={'warning'} onClick={() => navigate(-1)}>Cancelar</Button>
                <BotonEnviarPozo />
            </div>

        </div>
    );
}

export default DatosGeneralesPage;