import axios from 'axios';
import {Atom, atom} from "jotai";
import {getTokenAtom, logoutAtom} from "../users/user.atom";
import {pozoAtom} from "./pozos.atom";
import {round} from "../../../helpers/formatters";
import {enqueueSnackbar} from "notistack";

export interface trepanoModel {
    order: number;
    trepano_diametro: number | string;
    casing: number | string;
    mbt: number | string;
    densidad_lodo_promedio: number | string;
    humectacion_cutting: number | string;
    presion_desander_real: number | string;
    presion_desilter_real: number | string;
    tipo_malla: number | string;
}

export interface pozoCreationModel {
    operadora: string;
    equipo: string;
    area: string;
    pozo: string;
    coordenada_lat: number | string;
    coordenada_long: number | string;
    tipo_pozo: string;
    exploracion_desarrollo: string;
    fecha_ultimo_reporte: Date | null;
    trepano: trepanoModel[];
    mbt_aislacion: number | string;
    monobore: boolean;
    m3_evacuados_cutting_perforacion: number | string;
    m3_cutting_dewatering: number | string;
    m3_evacuados_cantera: number | string;
    m3_evacuado_teorico: number | string;
    agua_dewatering: number | string;
    destino: string;
    costo_locacion_seca: number | string;
    costo_control_solidos: number | string;
    m3_procesado_dewatering: number | string;
    kg_polimero_consumido: number | string;
    kg_m3_enviado_cantera: number | string;
    cantera_destino: string;
    horas_zaranda_fueraservicio: number | string;
    horas_centrifuga_fueraservicio: number | string;
    cantidad_batea_utilizadoxdia: number | string;
    distancia_cantera: number | string;
    viajes_a_cantera: number | string;
    m3_evacuados_por_viaje: number | string;
    cantidad_mallas_utilizadas: number | string;
    observaciones: string;
}

const newPozoInitialValues: pozoCreationModel = {
    operadora: '',
    equipo: '',
    area: '',
    pozo: '',
    coordenada_lat: '',
    coordenada_long: '',
    tipo_pozo: '',
    exploracion_desarrollo: 'Desarrollo',
    fecha_ultimo_reporte: null,
    trepano: [
        {
            order: 0,
            trepano_diametro: '',
            casing: '',
            mbt: '',
            densidad_lodo_promedio: '',
            humectacion_cutting: '',
            presion_desander_real: '',
            presion_desilter_real: '',
            tipo_malla: ''
        },
        {
            order: 1,
            trepano_diametro: '',
            casing: '',
            mbt: '',
            densidad_lodo_promedio: '',
            humectacion_cutting: '',
            presion_desander_real: '',
            presion_desilter_real: '',
            tipo_malla: ''
        },
        {
            order: 2,
            trepano_diametro: '',
            casing: '',
            mbt: '',
            densidad_lodo_promedio: '',
            humectacion_cutting: '',
            presion_desander_real: '',
            presion_desilter_real: '',
            tipo_malla: ''
        },
        {
            order: 3,
            trepano_diametro: '',
            casing: '',
            mbt: '',
            densidad_lodo_promedio: '',
            humectacion_cutting: '',
            presion_desander_real: '',
            presion_desilter_real: '',
            tipo_malla: ''
        }
    ],
    mbt_aislacion: '',
    monobore: false,
    m3_evacuados_cutting_perforacion: '',
    m3_cutting_dewatering: '',
    m3_evacuados_cantera: '',
    m3_evacuado_teorico: '',
    agua_dewatering: '',
    destino: '',
    costo_locacion_seca: '',
    costo_control_solidos: '',
    m3_procesado_dewatering: '',
    kg_polimero_consumido: '',
    kg_m3_enviado_cantera: '',
    cantera_destino: '',
    horas_zaranda_fueraservicio: 0,
    horas_centrifuga_fueraservicio: 0,
    cantidad_batea_utilizadoxdia: '',
    distancia_cantera: '',
    viajes_a_cantera: '',
    m3_evacuados_por_viaje: '',
    cantidad_mallas_utilizadas: '',
    observaciones: '',
};

export const loadPozosAtom = atom(null, async (get, set) => {
    const token = get(getTokenAtom);
    if (token) {
        set(pozoAtom, new Promise(() => {}));
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.get(`${process.env.REACT_APP_API_URL}/api/pozos`)
            .then((response) => {
                set(pozoAtom, response.data);
            })
            .catch(error => {
                if (error?.response?.status === 401){
                    set(logoutAtom);
                    enqueueSnackbar('Sesión de usuario expirada', {variant: 'error'})
                }
                else {
                    throw error;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
});

export const pozoCreationAtom = atom<pozoCreationModel>(newPozoInitialValues);


export const cleanPozoCreationAtom = atom(null, (get, set) => {
    set(pozoCreationAtom, newPozoInitialValues);
});

function getFactor(trepano: trepanoModel, multiplicador: number) {
    if (!trepano) return 0;
    const factorConversion = 0.0254;
    const pi = 3.14;

    return Math.pow((Number(trepano.trepano_diametro) * factorConversion) / 2, 2) * pi * Number(trepano.casing) * multiplicador * (1 + (Number(trepano.humectacion_cutting) / 100));
}

export const m3EvacuadosCuttingPerfAtom: Atom<number> = atom((get) => {
    const pozo = get(pozoCreationAtom);

    return round((getFactor(pozo.trepano[0], 1.2 * 1.65) + getFactor(pozo.trepano[1], 1.815) + getFactor(pozo.trepano[2], 1.815) + getFactor(pozo.trepano[3], 1.815)), 2);
});

export const m3EvacuadosEnviadosCanteraAtom: Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).m3_cutting_dewatering) + get(m3EvacuadosCuttingPerfAtom), 2);
});

export const m3_evacuados_teoricosAtom: Atom<number> = atom((get) => {
    return round(Number(get(m3EvacuadosEnviadosCanteraAtom))/Number(get(volumenTeoricoPozoAtom)), 2);
});

export const kg_m3_enviado_canteraAtom: Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).kg_polimero_consumido)/Number(get(m3EvacuadosCuttingPerfAtom)), 2);
});

export const m3_evacuado_por_viajeAtom: Atom<number> = atom((get) => {
   return round(Number(get(m3EvacuadosEnviadosCanteraAtom))/Number(get(pozoCreationAtom).viajes_a_cantera), 2);
});

export const mbt_promedioAtom: Atom<number> = atom((get) => {
   const mbts = get(pozoCreationAtom).trepano.filter((trepano) => Number(trepano.mbt) > 0).map((trepano) => Number(trepano.mbt));
    return mbts.reduce((a, b) => a + b, 0) / mbts.length;
});

export const tdAtom: Atom<number> = atom((get) => {
    const td = get(pozoCreationAtom).trepano.filter((trepano) => Number(trepano.casing) > 0).map((trepano) => Number(trepano.casing));
    return td.reduce((a, b) => a + b, 0);
});

export const volumenTeoricoPozoAtom: Atom<number> = atom((get) => {
   const trepano = get(pozoCreationAtom).trepano;
    const factorConversion = 0.0254;
    const pi = 3.14;

    return Math.round(trepano
        .map(trep => (Math.pow((Number(trep.trepano_diametro) * factorConversion) / 2, 2) * pi * Number(trep.casing)))
        .reduce((a, b) => a + b, 0));
});