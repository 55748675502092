import React from 'react';
import axios from "axios";
import {Button} from "@mui/material";
import {pozoCreationAtom} from "../../store/atoms/pozos/pozos.actions";
import {useAtomValue} from "jotai";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import { getTokenAtom } from '../../store/atoms/users/user.atom';
import {useAuthCheck} from "../../helpers/middleware";

function BotonEnviarPozo() {
    const pozo = useAtomValue(pozoCreationAtom);
    const navigate = useNavigate();
    const token = useAtomValue(getTokenAtom);
    const authCheck = useAuthCheck();
    const EnviarHandler = async () => {
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.post(`${process.env.REACT_APP_API_URL}/api/pozos`, pozo)
            .then(() => {
                enqueueSnackbar('Pozo creado con éxito', {variant: 'success'});
            })
            .then(() => {
                navigate('/');
            })
            .catch(authCheck)
            .catch((error) => {
                enqueueSnackbar(`Error al crear el pozo: ${error.response?.data?.message !== ''? error.response?.data?.message : 'Error inesperado'}`, {variant: 'error'});
            });
    }

    return (
        <Button variant="contained" color="success" onClick={() => EnviarHandler()}>Finalizar y crear</Button>
    );
}

export default BotonEnviarPozo;