import {atom} from "jotai";

export interface pozoModel {
    id:        number;
    operadora: string;
    nombre:    string;
    latitud:   number;
    longitud:  number;
    idEstado:  number;
    idTipo:    string;
    equipo:    string;
    area:      string;
    fecha:     Date;
    destino:  string;
}

const defaultModel: pozoModel[] = [];

export const pozoAtom = atom<pozoModel[] | Promise<pozoModel[]>>(defaultModel);

export const unloadPozosAtom = atom(null, (get, set) => {
    set(pozoAtom, defaultModel);
});

const filterDataSet = (pozos: pozoModel[] | Promise<pozoModel[]>, filter: string) => {
    if (!Array.isArray(pozos)) return [];
    const data = new Set<string>();
    // @ts-ignore
    pozos.forEach((pozo: pozoModel) => data.add(pozo[filter]));
    return Array.from(data);
}

export const operadorasAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'operadora');
});

export const equiposAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'equipo');
});

export const equiposAtom2 = atom((get) => {
    const pozos = get(pozoAtom);
    const equipos = filterDataSet(pozos, 'equipo');
    return equipos.map((equipo: string) => (!equipo || equipo.trim() === '') ? 'Sin equipo identificado' : equipo);
});

export const areasAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'area');
});

export const tiposPozoAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'idTipo');
});

export const destinosAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'destino');
});

export const canterasAtom = atom((get) => {
    const pozos = get(pozoAtom);
    return filterDataSet(pozos, 'cantera_destino');
});