import React from 'react';
import {FormHelperText, Input, InputLabel} from "@mui/material";
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import {useAtom} from "jotai";
import {pozoCreationAtom, pozoCreationModel} from "../../store/atoms/pozos/pozos.actions";

function CostosLocacion() {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof pozoCreationModel, newValue: null | string | boolean | number = null) => {
        setNewPozo({...newPozo, [field]: newValue || event.target.value});
    }



    return (
        <QuestionsContainer title={'Costos locación'}>
            <StyledFormControl>
                <InputLabel htmlFor="costos_locacion_seca">Costo locación seca $USD</InputLabel>
                <Input
                    id="costos_locacion_seca"
                    aria-describedby="costos_locacion_seca"
                    value={newPozo.costo_locacion_seca}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'costo_locacion_seca')}
                />
                <FormHelperText >Ejemplo: 65877.96</FormHelperText>
            </StyledFormControl>
            <br/>
            <StyledFormControl>
                <InputLabel htmlFor="costo_control_solidos">Costo Control de solidos $USD</InputLabel>
                <Input
                    id="costo_control_solidos"
                    aria-describedby="costo_control_solidos_USD"
                    value={newPozo.costo_control_solidos}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'costo_control_solidos')}
                />
                <FormHelperText >Ejemplo: 52636.91</FormHelperText>
            </StyledFormControl>
        </QuestionsContainer>
    );
}

export default CostosLocacion;
