import React from 'react';
import {Autocomplete, TextField} from "@mui/material";

function SuggestInputForm({label, suggestions, showValue, onChange, forceOptions}: any) {

    return (
        <div>
            <Autocomplete
                freeSolo={!forceOptions}
                options={suggestions}
                getOptionLabel={(option: string) => option || ""}
                value={showValue || ""}
                onChange={onChange}
                onInputChange={onChange}
                renderInput={(params) => <TextField {...params} label={label} variant="standard" sx={{
                    '.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root': {
                        transform: 'translate(14px, 20px) scale(1)',
                }
                }} />}
            />
        </div>
    );
}

export default SuggestInputForm;