import {Atom, atom} from "jotai";

import {m3EvacuadosCuttingPerfAtom, pozoCreationAtom, tdAtom} from "./pozos.actions";
import {round} from "../../../helpers/formatters";

export const costo_loc_seca_mas_control_solid: Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).costo_locacion_seca)+Number(get(pozoCreationAtom).costo_control_solidos), 2);
});

export const costo_loc_seca_entre_metro_perf: Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).costo_locacion_seca)/Number(get(tdAtom)), 2);
});

export const costo_control_solidos_entre_metro_perf: Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).costo_control_solidos)/Number(get(tdAtom)), 2);
});

export const costo_loc_seca_mas_control_solid_entre_metro_perf: Atom<number> = atom((get) => {
    return round(get(costo_loc_seca_mas_control_solid)/Number(get(tdAtom)), 2);
});

export const costo_loc_seca_entre_m3 : Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).costo_locacion_seca)/get(m3EvacuadosCuttingPerfAtom), 2);
});

export const costo_control_solidos_entre_m3 : Atom<number> = atom((get) => {
    return round(Number(get(pozoCreationAtom).costo_control_solidos)/get(m3EvacuadosCuttingPerfAtom), 2);
});

export const costo_loc_seca_mas_control_solid_entre_m3 : Atom<number> = atom((get) => {
    return round(get(costo_loc_seca_mas_control_solid)/get(m3EvacuadosCuttingPerfAtom), 2);
});