import React from 'react';
import {pozoAtom} from "../../store/atoms/pozos/pozos.atom";
import {useAtom} from "jotai";

import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns : GridColDef[] = [
    { field: 'operadora', headerName: 'Operadora', width: 130},
    { field: 'nombre', headerName: 'Pozo', width: 200 },
    { field: 'equipo', headerName: 'Equipo', width: 200 },
    { field: 'idTipo', headerName: 'Tipo', width: 130 },
    { field: 'fecha', headerName: 'Fecha', width: 130 },
    { field: 'latitud', headerName: 'Latitud', width: 130 },
    { field: 'longitud', headerName: 'Longitud', width: 130 },
    { field: 'estado', headerName: 'Observaciones', flex: 1},
];

export const ListadoPozos = () => {
    const [pozos] = useAtom(pozoAtom);
    return (

        <DataGrid
            rows={pozos}
            getRowId={(row) => row.nombre}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            checkboxSelection
            sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                },
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#1d1d1d',
                    color: 'white',
                    '& .MuiSvgIcon-root': {
                        color: 'white'
                    },
                    '& .MuiTouchRipple-root': {
                        color: 'white'
                    }
                },'& .MuiDataGrid-columnsContainer': {
                    backgroundColor: '#fafafa',
                },
                '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                },'& .MuiDataGrid-cell': {
                    color:
                        'rgba(0,0,0,0.95)',
                },
                '& .MuiPaginationItem-root': {
                    borderRadius: 0,
                },
        }}
        />

    )
}