import React from 'react';
import './import-file.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {useAtomValue} from "jotai";
import {getTokenAtom} from "../../store/atoms/users/user.atom";
import {useAuthCheck} from "../../helpers/middleware";

export const ImportarArchivo = () => {
    const navigate = useNavigate();
    const authCheck = useAuthCheck();
    const [dragActive, setDragActive] = React.useState(false);

    const token = useAtomValue(getTokenAtom);

    // handle drag events
    const handleDrag = function(e :any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            console.log(e.dataTransfer.files[0].name);
            handleFileUpload(e.target.files[0]);
        }
    };

    const handleChange = function(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0].name);
            handleFileUpload(e.target.files[0]);
        }
    };

    const handleFileUpload = (file: string | Blob) => {
        const formData = new FormData();
        formData.append('file', file);
        axios.post(`${process.env.REACT_APP_API_URL}/api/pozos/import-excel-file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
            }
        })
            .then(response => {
                navigate('/');
                console.log(response)
            })
            .catch(authCheck)
            .catch(error => {
                console.error(error)
            });
    }
    const inputRef = React.useRef(null);

    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input
                type="file"
                id="input-file-upload"
                multiple={false}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                onChange={handleChange}
                ref={inputRef}
            />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                <div>
                    <p>Arrastra y suelta el archivo aqui o <span>selecciona uno apretando aquí</span></p>
                </div>
            </label>
            { dragActive && (
                <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                />
            )}
        </form>
    );
}