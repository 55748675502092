import { useSetAtom } from 'jotai';
import {enqueueSnackbar} from 'notistack';
import {logoutAtom} from "../store/atoms/users/user.atom";
import {AxiosError} from "axios"; // Assuming you are using notistack for snackbars

export const useAuthCheck = () => {
    const logout = useSetAtom(logoutAtom);

    return (error: AxiosError) => {
        if (error?.response?.status === 401) {
            enqueueSnackbar('Sesión de usuario expirada.', { variant: 'error' });
            setTimeout(() => {
                logout();
            }, 800);
        } else {
            throw error;
        }
    };
};