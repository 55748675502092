import React from 'react';
import QuestionsContainer from "../formulario/QuestionsContainer";
import StyledFormControl from "../common/StyledFormControl";
import SuggestInputForm from "../formulario/SuggestInputForm";
import DivContainer1 from '../common/DivContainer1';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {getTokenAtom} from "../../store/atoms/users/user.atom";
import {useAtomValue} from "jotai";
import {useAuthCheck} from "../../helpers/middleware";
import TeamSelector from "../common/selectors/TeamSelector";

export const getYears = () : string[] => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2010; i--) {
        years.push(i.toString());
    }
    return years;
}

const cuatrimestres: ['Q1 - Enero a marzo', 'Q2 - Abril a junio', 'Q3 - Julio a septiembre', 'Q4 - Octubre a diciembre'] = [
    'Q1 - Enero a marzo',
    'Q2 - Abril a junio',
    'Q3 - Julio a septiembre',
    'Q4 - Octubre a diciembre'
];

type exportDataModel = {
    cuatrimestre: typeof cuatrimestres[number];
    year: string;
    equipos: string[];
}

export function selectCuatrimestre() : typeof cuatrimestres[number] {
    const currentMonth = new Date().getMonth();
    if (currentMonth >= 0 && currentMonth <= 2) {
        return 'Q1 - Enero a marzo';
    } else if (currentMonth >= 3 && currentMonth <= 5) {
        return 'Q2 - Abril a junio';
    } else if (currentMonth >= 6 && currentMonth <= 8) {
        return 'Q3 - Julio a septiembre';
    } else {
        return 'Q4 - Octubre a diciembre';
    }
}

const getCuatrimestre = (cuatrimestre: typeof cuatrimestres[number]) : number => {
    return cuatrimestres.indexOf(cuatrimestre);
}

const ExportForm = () => {

    const [exportData, setExportData] = React.useState<exportDataModel>({
        cuatrimestre: selectCuatrimestre(),
        year: new Date().getFullYear().toString(),
        equipos: []
    });

    const token = useAtomValue(getTokenAtom);
    const authCheck = useAuthCheck();

    const handleExport = () => {
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.get(`${process.env.REACT_APP_API_URL}/api/pozos/export?year=${exportData.year}&cuatrimestre=${getCuatrimestre(exportData.cuatrimestre)}&teams=${exportData.equipos}`, {
            responseType: 'blob' // Important: specify that the response should be treated as a Blob
        })
            .then(response => {
                if (response.status === 204){
                    enqueueSnackbar('No hay datos para exportar.', {variant: 'warning'});
                    return;
                }
                enqueueSnackbar('Pozo exportado con éxito.', {variant: 'success'});
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Pozos_${exportData.cuatrimestre}_${exportData.year}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(authCheck)
            .catch(error => {
                console.log(error)
                enqueueSnackbar(`Error inesperado al exportar el pozo`, {variant: 'error'});
            });
    }

    const navigate = useNavigate();

    return (
        <QuestionsContainer title={'Exportar pozo'}>
            <DivContainer1>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Cuatrimestre deseado'}
                        suggestions={cuatrimestres}
                        forceOptions={true}
                        showValue={exportData.cuatrimestre}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: typeof cuatrimestres[number]) => setExportData({...exportData, cuatrimestre: newValue})}
                    />
                </StyledFormControl>
                <StyledFormControl>
                    <SuggestInputForm
                        label={'Año'}
                        suggestions={getYears()}
                        forceOptions={true}
                        showValue={exportData.year}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => setExportData({...exportData, year: newValue})}
                    />
                </StyledFormControl>
            </DivContainer1>
            <StyledFormControl>
                <TeamSelector handleDataChange={(data: string[]) => setExportData({...exportData, equipos: data})} />
            </StyledFormControl>
            <div style={{display: 'flex', justifyContent: 'center', gap: '1em', marginTop: '2em'}}>
                <Button variant={'contained'} color={'warning'} onClick={() => navigate(-1)}>Cancelar</Button>
                <Button variant="contained" color="success" onClick={handleExport}>Exportar</Button>
            </div>
        </QuestionsContainer>
    );
};

export default ExportForm;