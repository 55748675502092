import React from 'react';
import {pozoCreationAtom} from "../../store/atoms/pozos/pozos.actions";
import StyledFormControl from "../common/StyledFormControl";
import {FormHelperText, Input, InputLabel} from "@mui/material";
import {useAtom} from "jotai/index";


const texHelperDot = ['Rellenar los valores para obtener la medición', 'Presión dentro de los valores normales esperados', 'Presión fuera de los valores normales esperados']

const DotIndicator = ({teorico, real}: {teorico: number, real: number}) : JSX.Element => {
    let color: string;
    if ((teorico === real && teorico === 0) || isNaN(teorico) || isNaN(real))
        color = '#bbb';
    else if ((real * 100)/teorico > 120 || (real * 100)/teorico < 80)
        color = '#cb0005';
    else
        color = '#6fe84c';

    return <span style={{
        height: '25px',
        width: '25px',
        backgroundColor: color,
        boxShadow: '1px 2px 3px 1px #000',
        transition: 'background-color 0.5s',
        borderRadius: '50%',
        display: 'inline-block',
        marginTop: '1em'
    }}
    title={texHelperDot[color === '#bbb' ? 0 : color === '#6fe84c' ? 1 : 2]}
    />;
}

const grlit2ppg = (grlit: number) => {
    return (grlit * 8.3454045)/1000;
}


function DetalleTrepanoSeccion(props: {
        seccion: number,
        name: string
    }) {

    const [newPozo, setNewPozo] = useAtom(pozoCreationAtom);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setNewPozo(prevPozo => {
            const newTrepanos = [...prevPozo.trepano];
            newTrepanos[props.seccion] = { ...newTrepanos[props.seccion], [name]: value };
            return { ...prevPozo, trepano: newTrepanos };
        });
    };

    const handleChangeNumberOnly = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const removeCommaAndDot = (value: string) => value.replace(/[^0-9]/g, '');

        setNewPozo(prevPozo => {
            const newTrepanos = [...prevPozo.trepano];
            newTrepanos[props.seccion] = { ...newTrepanos[props.seccion], [name]: Number(removeCommaAndDot(value)) };
            return { ...prevPozo, trepano: newTrepanos };
        });
    }

    const valor_desander_teorico: number = Math.round((grlit2ppg(Number(newPozo.trepano[props.seccion].densidad_lodo_promedio)) * 0.052 * 75 ) * 100) / 100;
    const valor_desilter_teorico: number = Math.round((grlit2ppg(Number(newPozo.trepano[props.seccion].densidad_lodo_promedio)) * 0.052 * 90) * 100) / 100;

    return <div style={{border: '1px black solid', borderRadius: '5px 5px 5px 5px', margin: '1em', padding: '1em'}}>
        <StyledFormControl>
            <InputLabel htmlFor={`trepano_diametro_${props.seccion}`}>Trepano diametro {props.name}</InputLabel>
            <Input
                name={'trepano_diametro'}
                id={`trepano_diametro_${props.seccion}`}
                aria-describedby="trepano_diametro"
                value={newPozo.trepano[props.seccion].trepano_diametro}
                onChange={handleChange}
            />
            <FormHelperText>Ejemplo: 8.75</FormHelperText>
        </StyledFormControl>
        <StyledFormControl>
            <InputLabel htmlFor={`casing_${props.seccion}`}>Casing (m) {props.name}</InputLabel>
            <Input
                name={'casing'}
                id={`casing_${props.seccion}`}
                aria-describedby="casing"
                value={newPozo.trepano[props.seccion].casing}
                onChange={handleChangeNumberOnly}
            />
            <FormHelperText>Ejemplo: 805</FormHelperText>
        </StyledFormControl>
        <StyledFormControl>
            <InputLabel htmlFor={`densidad_lodo_${props.seccion}`}>Densidad de lodo promedio {props.name}</InputLabel>
            <Input
                name={'densidad_lodo_promedio'}
                id={`densidad_lodo_${props.seccion}`}
                aria-describedby="densidad lodo promedio"
                value={newPozo.trepano[props.seccion].densidad_lodo_promedio}
                onChange={handleChangeNumberOnly}
            />
            <FormHelperText>en gramos/litros</FormHelperText>
        </StyledFormControl>
        <StyledFormControl>
            <InputLabel htmlFor={`mbt_${props.seccion}`}>MBT {props.name}</InputLabel>
            <Input
                name={'mbt'}
                id={`mbt_${props.seccion}`}
                aria-describedby="mbt"
                value={newPozo.trepano[props.seccion].mbt}
                onChange={handleChange}
            />
            <FormHelperText>Ejemplo: 10</FormHelperText>
        </StyledFormControl>
        <StyledFormControl>
            <InputLabel htmlFor={`humectacion_${props.seccion}`}> % Humectación cutting {props.name}</InputLabel>
            <Input
                name={'humectacion_cutting'}
                id={`humectacion_${props.seccion}`}
                aria-describedby="porcentaje humectacion cutting"
                value={newPozo.trepano[props.seccion].humectacion_cutting}
                onChange={handleChange}
            />
            <FormHelperText>Porcentaje de humectación de la sección</FormHelperText>
        </StyledFormControl>
        <StyledFormControl>
            <InputLabel htmlFor={`presion_desander_teorica${props.seccion}`}>Presión D-Sander teorica {props.name}</InputLabel>
            <Input
                name={'presion_desander_teorica'}
                id={`presion_desander_teorica${props.seccion}`}
                aria-describedby="presion desander teorica"
                value={valor_desander_teorico}
            />
            <FormHelperText>Valor teorico de la presion de D-Sander</FormHelperText>
        </StyledFormControl>
        <div style={{display: 'flex'}}>
            <StyledFormControl>
                <InputLabel htmlFor={`presion_desander_real_${props.seccion}`}>Presión D-Sander real {props.name}</InputLabel>
                <Input
                    name={'presion_desander_real'}
                    id={`presion_desander_real_${props.seccion}`}
                    aria-describedby="presion desander real"
                    value={newPozo.trepano[props.seccion].presion_desander_real}
                    onChange={handleChange}
                />
                <FormHelperText>Valor real de la presión D-Sander</FormHelperText>
            </StyledFormControl>
            <DotIndicator real={Number(newPozo.trepano[props.seccion].presion_desander_real)} teorico={valor_desander_teorico} />
        </div>
        <StyledFormControl>
            <InputLabel htmlFor={`presion_desilter_teorica${props.seccion}`}>Presión D-Silter teorica {props.name}</InputLabel>
            <Input
                name={'presion_desinder_teorica'}
                id={`presion_desilter_teorica${props.seccion}`}
                aria-describedby="presion desilter teorica"
                value={valor_desilter_teorico}
            />
            <FormHelperText>Valor teorico de la presion de D-Silter</FormHelperText>
        </StyledFormControl>
        <div style={{display: 'flex'}}>
            <StyledFormControl>
                <InputLabel htmlFor={`presion_desilter_real_${props.seccion}`}>Presión D-Silter real {props.name}</InputLabel>
                <Input
                    name={'presion_desilter_real'}
                    id={`presion_desilter_real_${props.seccion}`}
                    aria-describedby="presion desilter real"
                    value={newPozo.trepano[props.seccion].presion_desilter_real}
                    onChange={handleChange}
                />
                <FormHelperText>Valor real de la presión D-Silter</FormHelperText>
            </StyledFormControl>
            <DotIndicator real={Number(newPozo.trepano[props.seccion].presion_desilter_real)} teorico={valor_desilter_teorico}/>
        </div>
        <StyledFormControl>
            <InputLabel htmlFor={`tipo_malla${props.seccion}`}>Tipo de mallas utilizadas</InputLabel>
            <Input
                name={'tipo_malla'}
                id={`tipo_malla_${props.seccion}`}
                aria-describedby="tipo de malla utilizada"
                value={newPozo.trepano[props.seccion].tipo_malla}
                onChange={handleChange}
            />
            <FormHelperText>Tipo de malla utilizada</FormHelperText>
        </StyledFormControl>

    </div>;
}

export default DetalleTrepanoSeccion;