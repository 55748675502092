import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {LoginPage} from "./pages/LoginPage";
import { StyledEngineProvider } from '@mui/material/styles';
import {Provider, useAtom} from 'jotai';
import {isLoggedAtom} from "./store/atoms/users/user.atom";
import {Loading} from "./components/common/loading";
import AppRoutes from "./router";
import {BrowserRouter} from "react-router-dom";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const RenderApp = () => {
    const [isLoggedAtomValue] = useAtom(isLoggedAtom);
    if (!isLoggedAtomValue)
        return <LoginPage />;

    return <AppRoutes />;
}

root.render(
    <React.StrictMode>
        <Provider>
                    <StyledEngineProvider injectFirst>
                        <SnackbarProvider />
                        <Suspense fallback={<Loading/>}>
                            <BrowserRouter>
                                <RenderApp />
                            </BrowserRouter>
                        </Suspense>
                    </StyledEngineProvider>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
