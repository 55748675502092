import React from "react";
import {Button, Grid, LinearProgress, TextField} from "@mui/material";
import './style.css'
import {useAtom, useAtomValue} from "jotai";
import {checkLoginAtom, isLogginAtom, usuarioLocalAtom} from "../../store/atoms/users/user.atom";
import {errorsAtom} from "../../store/atoms/system/errors.atom";
import {Navigate} from "react-router-dom";
export const LoginPanel = () => {

    const [loggin] = useAtom(isLogginAtom);
    const [error] = useAtom(errorsAtom);
    const [username, setUsername] = useAtom(usuarioLocalAtom);
    const [, login] = useAtom(checkLoginAtom);
    const isLogged = useAtomValue(isLogginAtom);

    const [formValues, setFormValues] = React.useState({
        username: {
            value: username.usuario,
            error: 'No puede estár vacío',
            entered: false,
        },
        password: {
            value: '',
            error: 'No puede estár vacio',
            entered: false,
        },
    });

    const formRestrictions = {
        username: {
            minLength: 3,
            maxLength: 36,
        },
        password: {
            minLength: 5,
            maxLength: 255,
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        const error = checkForError(id as 'username' | 'password', id === 'username' ? 'Usuario' : 'Contraseña', value);
        setFormValues({...formValues, [id]: { ...formValues[id as keyof typeof formValues], value, error }});
    }

    const checkForError = (id: 'username' | 'password', name: string, value: string): string => {
        if (value.length < formRestrictions[id].minLength)
            return `${name} debe tener al menos ${formRestrictions[id].minLength} caracteres`;
        if (value.length >= formRestrictions[id].maxLength)
            return `${name} debe tener menos de ${formRestrictions[id].maxLength} caracteres`;
        return '';
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.ChangeEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setUsername({...username, usuario: formValues.username.value});
        setFormValues({...formValues, username: { ...formValues.username, entered: true }, password: { ...formValues.password, entered: true } })
        if (checkForError('username', 'Usuario', formValues.username.value) === '' &&
            checkForError('password', 'Contraseña', formValues.password.value) === '') {
            login({username: formValues.username.value, password: formValues.password.value});
            return;
        }
    }


    return (
        <div className='loginPanel'>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={12} textAlign='center'>
                        <h1>Iniciar sesión</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="username"
                            name="Usuario o correo"
                            label="Usuario o correo"
                            variant="outlined"
                            value={formValues.username.value}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                            disabled={loggin}
                            aria-errormessage={formValues.username.error}
                            error={formValues.username.error.length > 0 && formValues.username.entered}
                            onBlur={() => setFormValues({...formValues, username: { ...formValues.username, entered: true }})}
                            fullWidth
                        />
                        {formValues.username.entered &&
                            <span className='error'>{formValues.username.error}</span>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            name="Contraseña"
                            label="Contraseña"
                            type="password"
                            variant="outlined"
                            value={formValues.password.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
                            disabled={loggin}
                            aria-errormessage={formValues.password.error}
                            error={formValues.password.error.length > 0 && formValues.password.entered}
                            onBlur={() => setFormValues({...formValues, password: { ...formValues.password, entered: true }})}
                            fullWidth
                        />
                        {formValues.password.entered &&
                            <span className='error'>{formValues.password.error}</span>
                        }
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justifyContent="center">
                        <Button variant="contained" type="submit" disabled={loggin}>Iniciar Sesión</Button>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        {error.length > 0 && <p className='error'>{error[0]}</p>}
                    </Grid>
                    {loggin &&
                        <Grid item xs={12} textAlign='center'>
                            {isLogged &&
                                <Navigate to='/'/>
                            }
                            <LinearProgress />
                        </Grid>
                    }
                </Grid>
            </form>
        </div>
    );
};

