import React from "react";
import {AppBar, styled, Toolbar, Typography} from "@mui/material";
import {useAtom} from "jotai";
import {logoutAtom} from "../store/atoms/users/user.atom";
import {Link} from "react-router-dom";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #e4e6ea;
    font-size: 1.05rem;
  }
`;

const StyledLink2 = styled("div")`
    color: white;
    text-decoration: none;
    margin-left: 10px;
    font-weight: bold;
    
    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: #e4e6ea;
        font-size: 1.05rem;
    }
`;

export const MenuBar = () => {
    const [, setLogout] = useAtom(logoutAtom);

    const handleLogout = () => {
        setLogout();
    }

    return (
        <div className='menuBar'>
            <AppBar position="fixed" sx={{backgroundColor: '#282c34', color: 'white'}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "white" }}>
                        Reporteria de pozos - Tomrel
                    </Typography>
                    <StyledLink to='/'>
                        INICIO
                    </StyledLink>
                    <StyledLink to='/pozo/exportar'>
                        EXPORTAR
                    </StyledLink>
                    <StyledLink to='/importar-excel'>
                        IMPORTAR
                    </StyledLink>
                    <StyledLink to='/insertar-pozo/datos-generales'>
                        NUEVO
                    </StyledLink>
                    <StyledLink2 onClick={handleLogout}>
                        CERRAR SESION
                    </StyledLink2>
                </Toolbar>
            </AppBar>
        </div>
    );
};