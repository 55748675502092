import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

function FechaPicker(props: { dateValue: Date | null | undefined, onChange: (val: (Date | null)) => void, label: string, format: string, openTo?: "day" | "month" | "year" | undefined }) {
    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <DatePicker
            views={["year", "month", "day"]}
            openTo={props.openTo ?? "month"}
            label={props.label}
            format={props.format}
            value={props.dateValue}
            onChange={props.onChange}
        />
    </LocalizationProvider>;
}

export default FechaPicker;