import React from 'react';
import {ImportarArchivo} from "../components/Importar/ImportarArchivo";

export const ImportExcelPage = () => {
    React.useEffect(() => {
        document.title = 'Importar Excel';
        return () => {
            document.title = 'Cargando...';
        }
    }, []);

    return (
        <div className={'importarExcel'}>
            <ImportarArchivo />
        </div>
    );
};